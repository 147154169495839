import { Injectable, signal, WritableSignal } from '@angular/core';
import { LocalStorage } from '@bs24/universal/universal.providers';
import { BehaviorSubject } from 'rxjs';
import { Settings } from '../models/app-settings';
import { EnvConfig } from '../models/environment-config';

const STORAGE_KEY = 'AppSettings';

@Injectable({ providedIn: 'root' })
export class SettingsService {

  //private _settings!: Settings;
  settings!: WritableSignal<Partial<Settings>>;
  settings$: BehaviorSubject<Partial<Settings>> = new BehaviorSubject<Partial<Settings>>({});

  constructor(private config: EnvConfig, private localStorage: LocalStorage) {
    if (!this._settings) {
      this.restoreSettings();
    } else {
      this.settings = signal(this._settings);
    }
    //this.settings$ = toObservable(this.settings);
  }

  get _settings(): Partial<Settings> | undefined {
    const content = this.localStorage.getItem(STORAGE_KEY);
    if (content) {
      return JSON.parse(content);
    }
    return undefined;
  }

  set _settings(settings: Partial<Settings>) {
    this.localStorage.setItem(STORAGE_KEY, JSON.stringify(settings));
  }

  saveSetting(update: Partial<Settings>) {
    const current = this.settings();
    this.settings$.next(Object.assign(current, update));
    this._settings = Object.assign({}, current, update);
    this.settings.update(value => value ? Object.assign(value, update) : {});
  }


  private restoreSettings() {
    const settings: Settings = {
      darkTheme: this.config.theme.defaultDark,
      timeZone: this.config.timeZone,
      displayCurrency: this.config.currency,
      languageCode: 'en'
    };

    this._settings = settings;
    this.settings = signal(settings);
    //this.settings.update(value => value ? Object.assign(value, settings):{});

    /**
     *     this.oddFormat = OddFormat.Decimal;
     *     this.bookmakerSelectorDisabled = false;
     *     this.timeZone = this.config.timeZone;
     *     this.acceptOddsChanges = AcceptOddsChangesTypes.always;
     *     this.stake = 0;
     *     this.sportsbookGrid = false;*/

  }
}
